const routes = [{
  name: 'Contacts',
  path: 'contacts',
  component: () => import(/* webpackChunkName: "contact-main-view" */ '@/modules/contact/views/ContactsMainView.vue'),
  redirect: {
    name: 'ContactList'
  },
  meta: {
    title: 'Contactos'
  },
  children: [
    {
      name: 'ContactList', 
      path: '',
      component: () => import(/* webpackChunkName: "contact-list-view" */ '@/modules/contact/views/ContactListView.vue'),
      meta: {
        title: 'Contactos'
      },
    },
    { 
      name: 'ContactDetail',
      path: 'detail/:id',
      component: () => import(/* webpackChunkName: "contact-detail-view" */'@/modules/contact/views/ContactDetailView.vue'), 
      props: true,
      meta: {
        title: 'Contacto'
      },
    },
  ]
}]

export default routes