import { storeToRefs } from 'pinia'
import { provideApolloClients } from '@vue/apollo-composable'
import { isAfter, subMinutes } from 'date-fns'
import { pinia } from '@/main'
import useRefreshToken from '@/modules/shared/composables/useRefreshToken'
import { useRefreshTokenStore } from '@/modules/shared/stores/refresh.token.store'
import { apolloClientHodor } from '@/modules/shared/api/apolloClient'

const useCheckAndRefreshToken = async () => {
  const embed = location.hash.includes('embed')
  if (embed) return
  provideApolloClients({
    hodor: apolloClientHodor
  })
  const { token, dateExpiredToken } = storeToRefs(useRefreshTokenStore(pinia))
  const { refreshTokenMutation } = useRefreshToken()

  const isExpiredToken = isAfter(new Date(), subMinutes(dateExpiredToken.value, 1))

  if (!token.value || isExpiredToken) {
    try {
      await new Promise<void>((resolve, reject) => {
        refreshTokenMutation.mutate()
        refreshTokenMutation.onDone(() => {
          resolve()
        })
        refreshTokenMutation.onError((error) => {
          reject(error)
        })
      })
    } catch {
      throw new Error('Error refreshing token')
    }
  }

  return token.value
}

export default useCheckAndRefreshToken
