import axios from 'axios'
import authHeader from './auth-header'
// Modles
import { RequestGeneric } from '../models/requests.ts'
import { RequestType } from '../components/models.ts'
import { ConfigurationBusinessType, ConfigurationBusiness } from '../models/core-models'
import { V1, HOLIDAYS, ADMINISTRATIVE, PERMISSIONS, EXTRA_HOURS, NEW_EXTRA_HOURS } from './urls'
import emojiPalmTree from '@/assets/emojis/emoji_palm-tree.svg'
import emojiBalloon from '@/assets/emojis/emoji_balloon.svg'
import emojiPersonRaisingHand from '@/assets/emojis/emoji_person-raising-hand.svg'
import emojiAlarmClock from '@/assets/emojis/emoji_alarm-clock.svg'

class RequestsService {

  TYPE_ADMINISTRATIVE_REQUEST = 'administrativeDayRequest'
  TYPE_VACATION_REQUEST = 'vacationRequest'

  getRequests(settings, enabledServices){
    const requests = []

    const findConfig = (requestConfig) => settings.find(configuration => configuration.type === requestConfig)

    const holidayConfig = findConfig(ConfigurationBusinessType.HiddenHolidayRequest)
    const hideHolidayRequest = holidayConfig ? new ConfigurationBusiness(holidayConfig) : null
    if(!hideHolidayRequest?.dataValue()){
      requests.push({
        id: 'RequestVacations',
        label: 'Vacaciones',
        type: RequestType.Holidays,
        img: emojiPalmTree,
        to: 'RequestVacations'
      })
    }

    const administrativeConfig = findConfig(ConfigurationBusinessType.ShowAdministrativeDays)
    const showAdministrativeDays = administrativeConfig ? new ConfigurationBusiness(administrativeConfig) : null
    if(showAdministrativeDays?.dataValue()){
      const administrativeName = findConfig(ConfigurationBusinessType.AdministrativeDaysName)?.value
      requests.push({
        id: 'RequestAdministrative',
        label: administrativeName || 'Días administrativos',
        type: RequestType.Administratives,
        img: emojiBalloon,
        to: 'RequestAdministrative'
      })
    }

    const permissionConfig = findConfig(ConfigurationBusinessType.ConfigsRequestPermissions)
    const showPermissionRequest = permissionConfig ? JSON.parse(permissionConfig?.value) : []
    if(showPermissionRequest[0]?.permitir_solicitud){
      requests.push({
        id: 'RequestPermissions',
        label: 'Permisos',
        type: RequestType.Permissions,
        img: emojiPersonRaisingHand,
        to: 'RequestPermissions'
      })
    }

    const extraHoursConfig = findConfig(ConfigurationBusinessType.HiddenExtraHoursRequest)
    const hideExtraHoursRequest = extraHoursConfig ? new ConfigurationBusiness(extraHoursConfig) : null
    if(enabledServices?.asistenciaMobile && !hideExtraHoursRequest?.dataValue()){
      requests.push({
        id: 'RequestExtraHours',
        label: 'Horas extras',
        type: RequestType.ExtraHours,
        img: emojiAlarmClock,
        to: 'RequestExtraHours'
      })
    }
    return requests
  }


  /* Services Vacations */

  vacationReturnDate(queryParams = {}){
    return axios.get(`${V1()}/vacationReturnDate`, { withCredentials: true, headers: authHeader(), params: queryParams })
  }

  postVacationRequest(data){
    const url = `${HOLIDAYS()}/`
    const options = {
      method: 'POST',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }

  editRequestVacations(id, data){
    const url = `${HOLIDAYS()}/${id}/`
    const options = {
      method: 'PATCH',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }

  getRequestVacations(queryParams){
    return axios.get(`${HOLIDAYS()}/`, { withCredentials: true, headers: authHeader(), params: queryParams })
    .then(({data}) => {
      return {
        count: data.count,
        next: !!data.next,
        results: data.results.map(data => new RequestGeneric(data))
      }
    })
  }

  getRequestVacationDetail(id){
    const queryParams = {
      status: 'all'
    }
    return axios.get(`${HOLIDAYS()}/${id}/`, { withCredentials: true, headers: authHeader(), params: queryParams })
    .then(response => {
      return new RequestGeneric(response.data)
    })
  }


  deleteRequestVacations(id){
    return axios.delete(`${HOLIDAYS()}/${id}/`, { withCredentials: true, headers: authHeader() })
  }

  approveOrRejectRequest(queryParams){
    return axios.get(`${V1()}/approveOrRejectRequest/`, { withCredentials: true, headers: authHeader(), params: queryParams })
  }

  /* END Services Vacations */

  /* Services Administrative */

  getReasonsAdministrative(){
    return axios.get(`${V1()}/diasAdministrativosMotivoPersonaEmpresa/`, { withCredentials: true, headers: authHeader()})
  }

  administrativeReturnDate(queryParams = {}){
    const url = `${V1()}/dias-administrativos-return-date`
    const config = {
      method: 'GET',
      withCredentials: true,
      headers: authHeader(),
      params: queryParams,
      url,
    }
    return axios(config)
  }

  requestAdministrative(data){
    const url = `${ADMINISTRATIVE()}/`
    const options = {
      method: 'POST',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }

  editRequestAdministrative(id, data){
    const url = `${ADMINISTRATIVE()}/${id}/`
    const options = {
      method: 'PATCH',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }

  getRequestAdministrative(queryParams){
    return axios.get(`${ADMINISTRATIVE()}/`, { withCredentials: true, headers: authHeader(), params: queryParams })
    .then(response => {
      return response.data.map(data => new RequestGeneric(data))
    })
  }

  getRequestAdministrativeDetail(id){
    const queryParams = {
      status: 'all',
      //mine: true,
      //company: false
    }
    return axios.get(`${ADMINISTRATIVE()}/${id}/`, { withCredentials: true, headers: authHeader(), params: queryParams })
    .then(response => {
      return new RequestGeneric(response.data)
    })
  }

  deleteRequestAdministrative(id){
    return axios.delete(`${ADMINISTRATIVE()}/${id}/`, { withCredentials: true, headers: authHeader() })
  }

  /* END Services Administrative */

  /* Services Permissions */

  getPermissionsList(queryParams){
    return axios.get(`${PERMISSIONS()}/`, { withCredentials: true, headers: authHeader(), params: queryParams })
    .then(({data}) => {
      return data.map(data => new RequestGeneric(data))
    })
  }

  getPermissionReturnDate(queryParams){
    return axios.get(`${V1()}/permissionReturnDate`, { headers: authHeader(), params: queryParams })
  }

  deleteRequestPermissions(id){
    return axios.delete(`${PERMISSIONS()}/${id}/`, { withCredentials: true, headers: authHeader() })
  }

  getReasonsPermission(){
    return axios.get(`${PERMISSIONS()}/getCompanyAbsenceReason/`, { withCredentials: true, headers: authHeader()})
  }

  getPermissionDetail(id){
    const queryParams = {
      status: 'all',
    }
    return axios.get(`${PERMISSIONS()}/${id}/`, { withCredentials: true, headers: authHeader(), params: queryParams })
    .then(response => {
      return new RequestGeneric(response.data)
    })
  }

  postPermissionRequest(data){
    const url = `${PERMISSIONS()}/`
    const options = {
      method: 'POST',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }

  editRequestPermissions(id, data){
    const url = `${PERMISSIONS()}/${id}/`
    const options = {
      method: 'PUT',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }

  updatePermissionRequest(id, data){
    const url = `${PERMISSIONS()}/${id}/updateStatus/`
    const options = {
      method: 'PUT',
      withCredentials: true,
      headers: authHeader(),
      data,
      url,
    }
    return axios(options)
  }
  
  /* END Services Permissions */

  /* Services Extra Hours */

  requestExtraHours(data){
    const url = `${NEW_EXTRA_HOURS()}/`
    const options = {
      method: 'POST',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }

  getRequestExtraHours(queryParams){
    return axios.get(`${NEW_EXTRA_HOURS()}/`, { withCredentials: true, headers: authHeader(), params: queryParams })
    .then(response => {
      return response.data.map(data => new RequestGeneric(data))
    })
  }

  getExtraHoursDetail(id){
    const queryParams = {
      status: 'all',
    }
    return axios.get(`${NEW_EXTRA_HOURS()}/${id}/`, { withCredentials: true, headers: authHeader(), params: queryParams })
    .then(response => {
      return new RequestGeneric(response.data)
    })
  }

  updateStatusRequestExtraHours(id, data){
    const url = `${EXTRA_HOURS()}/${id}/updateStatus/`
    const options = {
      method: 'PUT',
      withCredentials: true,
      headers: authHeader(),
      data,
      url,
    }
    return axios(options)
  }

  editRequestExtraHours(id, data){
    const url = `${NEW_EXTRA_HOURS()}/${id}/`
    const options = {
      method: 'PUT',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }
  /* END Services Extra Hours */

  /* Services Marks */

  getCreateMarkMobile(data){
    const url = `${V1()}/validateMark/getCreateMarkMobile/`
    const options = {
      method: 'POST',
      withCredentials: true,
      headers: authHeader(),
      data: data,
      url,
    }
    return axios(options)
  }


}

export default new RequestsService()
