import { storeToRefs } from 'pinia'

import { useProfileStore } from '@/modules/profile/stores/profile.store'

const useProfile = () => {
  const { getProfile, getShifts, getBrandConfigurations, isBoss, getPermission, companyCountry } = useProfileStore()
  const { status, profile, shifts, brand } = storeToRefs(useProfileStore())

  return {
    status,
    profile,
    shifts,
    brand,
    getProfile,
    getShifts,
    getBrandConfigurations,
    isBoss,
    getPermission,
    companyCountry,
  }
}

export default useProfile