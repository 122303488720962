import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import useCheckAndRefreshToken from '@/modules/shared/composables/useCheckRefreshToken'
import useGatewayHost from '@/modules/shared/composables/useGatewayHost'

const { BASE_HOST } = useGatewayHost()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const linkHeaders = setContext(async (_, { headers }) => {
  let authorizationHeader = ''
  
  if (process.env.NODE_ENV !== 'development') {
    try {
      const token = await useCheckAndRefreshToken()
      if (token) {
        authorizationHeader = `Bearer ${token}`
      }
    } catch (error) {
      return Promise.reject(error)
    }
  } else {
    authorizationHeader = `Bearer ${process.env.VUE_APP_TOKEN_HODOR}`
  }

  return {
    headers: {
      ...headers,
      ...(authorizationHeader && { authorization: authorizationHeader })
    }
  }
})

const httpLinkHodor = createHttpLink({
  uri: `${BASE_HOST}/hodor/graphql`,
  credentials: 'include'
})

const cache = new InMemoryCache({
  addTypename: false
})

export const apolloClientHodor = new ApolloClient({
  link: ApolloLink.from([httpLinkHodor]),
  cache,
})
