import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { AUTH } from '@/services/urls'

class AuthService {
  loginJWT(queryParams){
    localStorage.removeItem('API_URL')
    localStorage.removeItem('API_SASS')
    localStorage.removeItem('SHORT_CODE')

    if(queryParams.token){
      const decoded = jwtDecode(queryParams.token)
      if(!!decoded.core_host && !!decoded.kong_host){
        localStorage.setItem('API_URL', decoded.kong_host)
        localStorage.setItem('API_SASS', decoded.core_host)
        if(!!decoded.server_shortcode && !decoded.server_shortcode?.includes('QA')){
          localStorage.setItem('SHORT_CODE', decoded.server_shortcode)
        }
      }
    } 
    
    return axios.get(`${AUTH()}/v2/`, { params: queryParams })
    .then(response => {
      if (response.data.talana_token) {
        localStorage.setItem('user', JSON.stringify(response.data))
      }
      return response.data
    })
  }
  login(user) {
    
    const bodyFormData = new FormData()
    bodyFormData.set('username', user.username)
    bodyFormData.set('password', user.password)
    
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

    return axios({
      method: 'post',
      data: bodyFormData,
      url: `${AUTH()}/`,
      headers: {'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.data.talana_token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
  }

  logout() {
    localStorage.removeItem('configurations')
    localStorage.removeItem('profile')
    localStorage.removeItem('user')
    localStorage.removeItem('API_URL')
    localStorage.removeItem('API_SASS')
    localStorage.removeItem('SHORT_CODE')
  }
}

export default new AuthService()