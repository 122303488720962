import { pinia } from '@/main'
import { useMutation } from '@vue/apollo-composable'
import { jwtDecode } from 'jwt-decode'
import { fromUnixTime } from 'date-fns'
import { useRefreshTokenStore } from '@/modules/shared/stores/refresh.token.store'
import { REFRESH_TOKEN_MUTATION } from '@/modules/shared/queries/refresh.token.graphql'
import { BASE_API_SASS } from '@/services/config'
import AuthService from '@/modules/shared/services/auth.service'

import type { JwtHodorInterface } from '@/modules/shared/models/hodor.models'

const useRefreshToken = () => {
  const { setToken, setDateExpiredToken } = useRefreshTokenStore(pinia)

  const refreshTokenMutation = useMutation(
    REFRESH_TOKEN_MUTATION,
    () => ({
      clientId: 'hodor'
    })
  )
  refreshTokenMutation.onDone(({data}) => {
    const token = data?.refreshToken?.token
    const decoded: JwtHodorInterface = jwtDecode(token)
    const expirationDate = fromUnixTime(decoded.exp)
    setToken(token)
    setDateExpiredToken(expirationDate)
  })
  refreshTokenMutation.onError(() => {
    AuthService.logout()
    useRefreshTokenStore(pinia).$reset()
    const baseURL = BASE_API_SASS()
    window.open(`${baseURL}/es/remuneraciones/logout`, '_self')
  })

  return {
    refreshTokenMutation,
  }
}

export default useRefreshToken