import {RequestGeneric, RequestState, RequestType} from '../models/requests'
import { ConfigurationBusinessType } from '../models/core-models'
import RequestsService from '../services/requests.service.js'
import notify from '@/boot/notify-defaults'
import emojiPalmTree from '@/assets/emojis/emoji_palm-tree.svg'
import emojiBalloon from '@/assets/emojis/emoji_balloon.svg'
import emojiAlarmClock from '@/assets/emojis/emoji_alarm-clock.svg'
import emojiSpeaker from '@/assets/emojis/emoji_speaker.svg'
import emojiPersonRaisingHand from '@/assets/emojis/emoji_person-raising-hand.svg'

function sortByDate(a, b) {
  if ( a.dateCreated > b.dateCreated ){
    return -1
  }
  if ( a.dateCreated < b.dateCreated ){
    return 1
  }
  return 0
}

export const requests = {
  namespaced: true,
  state: {
    requests: Array<RequestGeneric[]>(),
    loading: true,
    availableRequests: [],
    holidayPage: 1,
    holidayCount: 0,
  },
  mutations: {
    showLoading(state){
      state.loading = true
    },
    hideLoading(state){
      state.loading = false
    },
    setRequests(state, requests){
      state.requests = requests
    },
    setAvailableRequests(state, requests){
      state.availableRequests = requests
    },
    setHolidayPage(state, pagination){
      state.holidayPage = pagination
    },
    setHolidayCount(state, count){
      state.holidayCount = count
    }
  },
  actions: {
    resetRequests({commit}){
      commit('setRequests', [])
    },
    getRequestsTickets({commit, state}, params){
      commit('setHolidayPage', 1)
      commit('setRequests', [])
      commit('showLoading')

      const vacationsRequest = RequestsService.getRequestVacations(params)
      const administratuveRequest = RequestsService.getRequestAdministrative(params)
      const permissionsRequest = RequestsService.getPermissionsList(params)
      const statusExtraHours = {
        [RequestState.Pending]: 'pending',
        [RequestState.Accepted]: 'approved',
        [RequestState.Rejected]: 'rejected',
        [RequestState.Canceled]: 'canceled'
      }
      const paramsExtraHours = { ...params, status: statusExtraHours[params.status] }
      const extraHoursRequest = RequestsService.getRequestExtraHours(paramsExtraHours)

      return Promise.all([
        vacationsRequest,
        administratuveRequest,
        permissionsRequest,
        extraHoursRequest
      ]).then(values => {
          let requestsConcats: RequestGeneric[] = []
          values.forEach((requests, value) => {
            requestsConcats = value ? requestsConcats.concat(requests) : requestsConcats.concat(requests.results)
          })
          requestsConcats.sort(sortByDate)
          commit('setRequests', requestsConcats)
          commit('hideLoading')
          const page = values[0].next ? state.holidayPage + 1 : 0
          commit('setHolidayCount', values[0].count)
          commit('setHolidayPage', page)
        },
        () => {
          commit('hideLoading')
        }
      )
    },
    requestExtraHours({commit}, payload){
      commit('showLoading')
      const requestService = payload.id ? RequestsService.editRequestExtraHours(payload.id, payload.instance) : RequestsService.requestExtraHours(payload.instance)
      return requestService.then(
        response => {
            if(response.data){
              commit('hideLoading')
              notify.sendNotification({
                message: `Solicitud ${payload.id ? 'editada' : 'creada'} con éxito`,
                type: 'success'
              })
              return true
            }
          },
          ({response: { data } = { data: {} as { usuario: string } }}) => {
            notify.sendNotification({
              message: data?.usuario || 'Ocurrió un error, por favor intente más tarde',
              type: 'error'
            })
            commit('hideLoading')
            return false
          }
      )
    },
    async getRequests({commit, rootGetters}){
      const settings = rootGetters['configurations/getSettings']
      const enabledServices = rootGetters['configurations/getEnabledServices']

      try{
        const requests = await RequestsService.getRequests(settings,enabledServices)

        if(requests){
          commit('setAvailableRequests', requests)
        }else{
          commit('setAvailableRequests', [])
        }
      }catch {
        commit('setAvailableRequests', [])
      }
    },
    getExtraHours({commit, state}, params){
      const queryParams = {
        ...params,
        page: state.holidayPage
      }
      return RequestsService.getRequestVacations(queryParams).then(
        data => {
          const requests = [...state.requests, ...data.results]
          requests.sort(sortByDate)
          commit('setRequests', requests)
          const page = data.next ? state.holidayPage + 1 : 0
          commit('setHolidayPage', page)
        }
      )
    },
    cancelRequest({commit, state}, params){
      const index = state.requests.findIndex(request => request.remoteID === Number(params.id))
      if(index){
        const requests = [...state.requests]
        if(params.type === RequestType.ExtraHours){
          requests[index].state = 'C'
        }else{
          requests.splice(index, 1)
        }
        commit('setRequests', requests)
      }
    },
    updateStatus({commit, state}, params){
      const index = state.requests.findIndex(request => request.remoteID === Number(params.id))
      if(index){
        const requests = [...state.requests]
        requests[index].state = params.action
        commit('setRequests', requests)
      }
    }
  },
  getters: {
    hoursAndMinutes: () => (floathours:number) => {
      const hours = Math.trunc(floathours)
      const minutes = Math.round((floathours - hours) * 60)
      return {
        hours: hours,
        minutes: minutes
      }
    },
    administrativeDaysName: (state, getters, rootState, rootGetters) => (days:number = 0):string => {
      const administrativeName = rootGetters['configurations/getSettingType'](ConfigurationBusinessType.AdministrativeDaysName)?.value
      if(administrativeName && administrativeName !== 'Días administrativos'){
        return administrativeName
      }
      return !days || days > 0 ? 'días administrativos' : 'día administrativo'
    },
    getAvailableRequest(state){
      return state.availableRequests
    },
    requestsLength(state){
      return state.requests?.length || 0
    },
    holidayPagination(state){
      return {
        count: state.holidayCount,
        page: state.holidayPage
      }
    },
    requestIcon: () => (type: string): string => {
      switch(type){
        case 'vacaciones':
        case 'holidays':
        case 'vacation_request':
        case 'vacation_changed':
          return emojiPalmTree
        case 'día administrativo':
        case 'administratives':
        case 'administrative_request':
        case 'administrative_changed':
          return emojiBalloon
        case 'horas extras':
        case 'extrahours':
        case 'extra_hours_request':
        case 'extra_hour_changed':
          return emojiAlarmClock
        case 'assistance_message':
          return emojiSpeaker
        case 'permiso':
        case 'permissions':
        case 'permission_request':
        case 'permission_changed':
          return emojiPersonRaisingHand
        default:
          return ''
      }
    }
  }
}
