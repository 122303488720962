import { computed } from 'vue'

const useGatewayHost = () => {
  const isGatewayHost = computed(() => {
    const host = window.location.host
    const qaRegex = /^mi\.qa-.*\.talana\.dev$/
    return host.includes('localhost') || host.includes('gateway') || qaRegex.test(host)
  })

  const BASE_URL = 'https://gateway.talana.dev'
  const BFF_URL = 'https://api.talana.com/bff-admin'
  
  const BASE_HOST: string = isGatewayHost.value ? 
    `${BASE_URL}/paris-admin` : 
    BFF_URL

  const BASE_HOST_PARIS_WEB: string = isGatewayHost.value ? 
    `${BASE_URL}/paris-web` : 
    BFF_URL
  
  return {
    BASE_HOST,
    BASE_HOST_PARIS_WEB
  }
}

export default useGatewayHost
