import { defineStore } from 'pinia'

export interface State {
  dateExpiredToken: Date | string | number
  token: string
}

export const useRefreshTokenStore = defineStore({
  id: 'refreshToken',
  state: (): State => ({
    dateExpiredToken: '',
    token: process.env.VUE_APP_TOKEN_HODOR || ''
  }),
  actions: {
    setDateExpiredToken (expiredDate: Date | string | number) {
      this.dateExpiredToken = expiredDate
    },
    setToken (token: string) {
      this.token = token
    },
  }
})
