import BenefitsService from '../services/benefits.service'
import { BenefitCategory, Benefit } from '../models/benefits'
import notify from '../boot/notify-defaults'

function shuffleBenefits(benefits: Benefit[] = []) {
  return benefits.map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
}

export const benefits = {
  namespaced: true,
  state: {
    benefits: {}
  },
  mutations: {
    setBenefits(state, benefits){
      state.benefits = benefits
    },
    setFavBenefit(state, benefit){
      const featured = state.benefits.highlighted.findIndex(ben => ben.id === benefit.id)
      const category = state.benefits.categories.findIndex(cat => cat.title === benefit.category_title)
      if(featured >= 0){
        state.benefits.highlighted[featured].is_favorite = !benefit.is_favorite
      }
      if(category >= 0){
        const ben = state.benefits.categories[category].benefits.findIndex(b => b.id === benefit.id)
        if(ben >= 0){
          state.benefits.categories[category].benefits[ben].is_favorite = !benefit.is_favorite
        }
      }
    },
    setTermsAgreement(state, benefit){
      const featured = state.benefits.highlighted.findIndex(ben => ben.id === benefit.id)
      const category = state.benefits.categories.findIndex(cat => cat.title === benefit.category_title)
      if(featured >= 0){
        state.benefits.highlighted[featured].agreed_with_tac = true
      }
      if(category >= 0){
        const ben = state.benefits.categories[category].benefits.findIndex(b => b.id === benefit.id)
        if(ben >= 0){
          state.benefits.categories[category].benefits[ben].agreed_with_tac = true
        }
      }
    }
  },
  actions: {
    async getBenefits({commit}, params){
      try {
        const { data } = await BenefitsService.getBenefits(params)
        const benefits = data.value
        if(params?.keyword){
          return benefits
        }
        commit('setBenefits', benefits)
        return true
      } catch {
        return false
      }
    },
    async toggleFavBenefit({commit}, benefit: Benefit){
      try {
        const payload = {
          benefit_id: benefit.id,
        }
        commit('setFavBenefit', benefit)
        await BenefitsService.toggleFavBenefit(payload)
        return true
      } catch {
        commit('setFavBenefit', {...benefit, is_favorite: !benefit.is_favorite})
        notify.sendNotification({
          message: `Error al ${benefit.is_favorite ? 'eliminar' : 'guardar'} favorito. Intente más tarde.`,
          type: 'error'
        })
        return false
      }
    },
    async getFavoriteBenefits(){
      try {
        const { data } = await BenefitsService.getFavBenefits()
        return data
      } catch {
        return false
      }
    },
    async agreeTermsAndConditions({commit}, benefit){
      try{
        const payload = {
          benefit_id: benefit?.id
        }
        const { data } = await BenefitsService.agreeTermsAndConditions(payload)
        commit('setTermsAgreement', benefit)
        return data.success
      } catch {
        return false
      }
    }
  },
  getters: {
    categorizedBenefits(state): BenefitCategory[]{
      return state.benefits?.categories || []
    },
    featuredBenefits(state): Benefit[]{
      return state.benefits?.highlighted || []
    },
    benefitsByCategory: (_, getters) => (categoryId: number, benefitId: number) => {
      const category = getters.categorizedBenefits.filter(cat => cat.category_id === categoryId)
      const filteredBenefits = category[0]?.benefits.filter(benefit => benefit.id !== benefitId)
      return shuffleBenefits(filteredBenefits)
    }
  }
}